import * as React from 'react';
import {useEffect} from 'react';
import Cookies from 'js-cookie';
import {
  BASE_URL,
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
  KEYCLOAK_URL,
  CLIENT_COOKIE,
} from '../../common/constants';
import Keycloak, {KeycloakInitOptions} from 'keycloak-js';
import isLocalStorageAvailable from '../../common/utils/isLocalStorageAvailable';
import {
  B2CComponentsProvider,
  PageLoadingError,
} from '@edekadigital/b2c-components';
import sleep from '../../common/utils/sleep';

const StartLoginV2: React.FC = () => {
  return <>Hello Universal link!</>;
};

export default StartLoginV2;
